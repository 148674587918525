import { useContext, useEffect } from "react";
import NavigationBar from "../../Components/HighLevelComponents/NavigationBar/NavigationBar";
import Button from "../../Components/Input/Button/Button";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Components/Text/MainText/MainText";
import TitleText from "../../Components/Text/TitleText/TitleText";
import config from "../../config";
import { useAuthenticatedFetch } from "../../Hooks/useFetch";
import { BsFillCheckCircleFill } from "react-icons/bs";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import { GoogleAnalyticsContext } from "../../Hooks/GoogleAnalyticsContext";

const Feature = ({ feature }: { feature: string }) => {
  return (
    <HorizontalFlexContainer alignItems="center">
      <BsFillCheckCircleFill size={20} />
      <Spacer width="4px" />
      <MainText>{feature}</MainText>
    </HorizontalFlexContainer>
  );
};

function Pricing() {
  const { callAPI, res, isLoading } = useAuthenticatedFetch({
    url: config.API_URI + `/create-checkout-session`,
    method: "POST",
  });
  const { logEvent } = useContext(GoogleAnalyticsContext);

  const submitSubscription = async () => {
    logEvent("Interaction", "Subscribe");
    callAPI(
      JSON.stringify({
        priceId: config.PRICE_ID,
      })
    );
  };

  useEffect(() => {
    if (res.statusCode === 200) {
      window.open(res.data.url, "_self");
    }
  }, [res]);
  return (
    <PageWrapper>
      <NavigationBar />
      <VerticalFlexContainer
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        padding="20px"
      >
        <Spacer height="6vh" />
        <TitleText fontSize="40px">
          Unlimited access to the best AI study tool
        </TitleText>
        <Spacer height="4vh" />
        <VerticalFlexContainer
          backgroundColor="rgba(0, 0, 0, 0.125)"
          padding="40px"
          borderRadius="10px"
          marginBottom="10px"
          width="440px"
        >
          <TitleText fontSize="30px">Early Bird Plan</TitleText>
          <HorizontalFlexContainer justifyContent="center">
            <MainText>(Limited time offer)</MainText>
          </HorizontalFlexContainer>
          <Spacer height="5px" />
          <TitleText fontSize="20px">$4.99 / mo</TitleText>
          <Spacer height="20px" />
          <Feature feature="Unlimited question generation" />
          <Feature feature="Superior AI question generation" />
          <Feature feature="Priority access to new features" />
          <Feature feature="Price guarantee as an early supporter" />
          <Spacer height="10px" />
          <Button onClick={submitSubscription} loading={isLoading}>
            Subscribe
          </Button>
        </VerticalFlexContainer>
      </VerticalFlexContainer>
    </PageWrapper>
  );
}

export default Pricing;
