import { Link } from "react-router-dom";
import NavigationBar from "../../Components/HighLevelComponents/NavigationBar/NavigationBar";
import Button from "../../Components/Input/Button/Button";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import demo from "../../images/demo.png";
import demo1 from "../../images/demo_1.png";
import { Helmet } from "react-helmet";

import { BsFillCheckCircleFill } from "react-icons/bs";

const Feature = ({ feature }: { feature: string }) => {
  return (
    <div className="flex items-center justify-center mb-2">
      <span className="text-xl mr-4">
        <BsFillCheckCircleFill size={15} />
      </span>
      <span className="text-sm">{feature}</span>
    </div>
  );
};

function PricingCard({
  price,
  features,
  planName,
  subtitle,
}: {
  price: string;
  features: string[];
  planName: string;
  subtitle?: string;
}) {
  return (
    <div className="flex flex-col p-10 rounded-xl m-2.5 w-72 h-96 shadow-sm bg-[#ffc60b26]">
      <h3 className="font-bold text-2xl leading-12 text-gray-700 text-left">
        {planName}
      </h3>
      <h4 className="text-xl">${price} / mo</h4>
      <div className="h-5"></div>
      {features.map((feature, i) => (
        <Feature feature={feature} key={i} />
      ))}
      <div className="flex-1"></div>
      <div className="flex items-center justify-center">
        <Link
          to="/signup"
          className="flex items-center justify-center no-underline"
        >
          <Button onClick={() => {}}>SIGN UP</Button>
        </Link>
      </div>
    </div>
  );
}

function LandingPage() {
  return (
    <>
      <Helmet>
        <title>
          Yippity | AI Question Generator: Instant Multiple Choice, True/False,
          Fill-in-the-Blank Questions & More!
        </title>
        <meta
          name="description"
          content="Yippity: Your go-to platform for generating AI-driven questions. Convert any text into a quiz - from multiple choice to fill-in-the-blanks, effortlessly."
        />
      </Helmet>
      <PageWrapper>
        <NavigationBar />
        <div className="flex justify-center flex-col items-center h-full container">
          <div className="h-[8vh]" />
          <div className="flex flex-col max-w-md">
            <h1 className="font-bold text-4xl text-center leading-12 text-gray-700 break-words leading-normal">
              AI Question Generator: Study{" "}
              <span className="font-bold text-4xl text-center leading-12 text-gray-700 break-words bg-[#FFC60B] p-1 rounded-md">
                Smarter
              </span>{" "}
              with Automated Quizzes
            </h1>
            <div className="h-6" />
            <p className="text-gray-700 text-md text-center">
              Transform text into multiple choice, true/false, or
              fill-in-the-blank quizzes automatically with our AI-powered
              question generator.
            </p>
          </div>
          <div className="h-6" />
          <Link
            to="/signup"
            style={{
              textDecoration: "none",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button onClick={(e) => {}}>Generate Questions</Button>
          </Link>
          <p className="text-gray-700 text-xs text-center">
            (No payment method required)
          </p>
          <div className="h-12" />
          <h2 className="font-bold text-3xl text-gray-700 text-center">
            Generates Quizzes Automatically
          </h2>
          <div className="max-w-md flex">
            <p className="text-gray-700 text-md text-center">
              Simply paste your text and let our AI question generator create a
              comprehensive quiz for you.
            </p>
          </div>
          <div className="h-6" />
          <img
            className="rounded-xl max-w-xs md:max-w-xl"
            src={demo}
            // style={{
            //   width: "60vw",
            //   borderRadius: "20px",
            //   maxWidth: "700px",
            //   minWidth: "400px",
            // }}
          />
          <div className="h-10" />
          <h2 className="font-bold text-3xl text-gray-700 text-center">
            Edit and export your generated quizzes
          </h2>
          <div className="flex max-w-md">
            <p className="text-gray-700 text-md text-center">
              Review and customize your generated quiz, then easily export them
              to test yourself or share with others.
            </p>
          </div>
          <div className="h-6" />
          <img src={demo1} className="rounded-xl max-w-xs md:max-w-lg" />
          <div className="h-10" />
          <div className="mt-8 mb-8 text-center bg-cover w-80 mx-auto">
            <h2 className="font-bold text-3xl text-gray-700 text-center">
              Top Features
            </h2>
            <ul className="list-none p-0">
              <li className="flex items-center justify-center mb-2">
                <span className="text-md mr-4">🤖</span>
                <span className="text-md text-left">
                  Generate AI-driven multiple choice questions instantly
                </span>
              </li>
              <li className="flex items-center justify-center mb-2">
                <span className="text-md mr-4">✔️</span>
                <span className="text-md text-left">
                  Effortless true or false question creation
                </span>
              </li>
              <li className="flex items-center justify-center mb-2">
                <span className="text-md mr-4">✍️</span>
                <span className="text-md text-left">
                  Fill-in-the-blank questions made easy
                </span>
              </li>
              <li className="flex items-center justify-center mb-2">
                <span className="text-md mr-4">🎓</span>
                <span className="text-md text-left">
                  High-quality question maker for educators and students
                </span>
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-around flex-wrap">
            <div className="flex flex-col max-w-lg">
              <div className="max-w-sm">
                <h3 className="font-bold text-3xl leading-12 text-gray-700 text-left">
                  Pricing Is Actually Pretty Simple
                </h3>
                <p className="text-gray-700 text-left text-md">
                  Try it for free and if you like it, sign up to the premium
                  service to access the best possible experience! No credit card
                  required for the free trial!
                </p>
              </div>
              <div className="h-10" />
            </div>
            <PricingCard
              price="0"
              planName="Free version"
              features={["3 Free AI quiz generations per month"]}
            />

            <PricingCard
              price="4.99"
              planName="Early Bird Plan"
              features={[
                "Unlimited AI question generation",
                "Superior AI question generation",
                "Priority access to new features",
                "Price guarantee as an early supporter",
              ]}
            />
          </div>
          <div className="h-10" />
          <a href="https://promptologer.com/" target="_blank">
            Powered By Promptologer
          </a>
          <div className="h-10" />
        </div>
      </PageWrapper>
    </>
  );
}

export default LandingPage;
